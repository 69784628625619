import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['projects', 'projectArrow', 'mobileMenu'];

  toggleProjects() {
    this.projectsTargets.map((el) => el.classList.toggle('hidden'));
    this.projectArrowTargets.map((el) => el.classList.toggle('rotate-90'));
  }

  closeMobileMenu() {
    // Show Mobile Menu is handled in navbar controller
    this.mobileMenuTarget.classList.add('hidden');
  }
}