import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [];

  moveSelected(event) {
    const selectedPhotoIds = this.findSelected().map(el => el.getAttribute('data-photo-id'))
    event.target.setAttribute("data-photo-selected-ids", selectedPhotoIds);
    if (selectedPhotoIds.length > 0) {
      this.stimulate('photo_actions#move_selected', event.target);
    }
    this.emit('drop-down-close-event');

    event.stopImmediatePropagation();
  }

  confirmDeletePhoto(event) {
    this.stimulate('photo_actions#delete_photo_confirm', event.currentTarget);
    event.stopImmediatePropagation();
  }

  deletePhoto(event) {
    this.stimulate('photo_actions#delete_photo', event.currentTarget);
    event.stopImmediatePropagation();
  }

  cancelDeletePhoto(event) {
    const elem = document.querySelector('#modal');
    elem.innerHTML = "";
  }

  clearSelected(event) {
    this.findSelected().forEach((el) => el.checked = false);
  }

  findSelected() {
    const nodeList = document.querySelectorAll('[name="selected"]:checked');
    return Array.from(nodeList);
  }

  hideMenu(event) {
    this.dropMenuTarget.classList.add('hidden');
  }

  afterReflex (element, reflex, error) {
    if (!error && reflex == 'photo_actions#delete_photo') {
      this.hideModal();
      const photoElem = document.querySelector(`li[data-photo-id='${element.dataset.photoId}']`)
      photoElem.remove();
    }
  }

  hideModal() {
    const modalElem = document.querySelector("#modal")
    modalElem.innerHTML = "";
  }
}
