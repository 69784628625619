import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter,
} from "helpers";
import Compressor from 'compressorjs';

export default class extends Controller {
  static targets = ["input"];
  static MAX_WIDTH = 2400;
  static MAX_HEIGHT = 2400;

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    const imageFormats = this.imageFormats;
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        const controller = this;
        const nameSplit = file.name.split('.')
        const imageFile = imageFormats.includes(nameSplit[nameSplit.length-1])
        if (!file.accepted) {
          return;
        }
        if (imageFile) {
          file.accepted && new Compressor(file, {
            maxWidth: this.MAX_WIDTH,
            maxHeight: this.MAX_HEIGHT,
            success(result) {
              Object.assign(result, file);
              createDirectUploadController(controller, result).start()
            },
            error(err) {
              console.log(err.message);
            },
          });
        } else {
          createDirectUploadController(controller, file).start()
        }
      }, 500);
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get imageFormats() {
    return ['jpeg','jpg','png']
  }

  get videoFormats() {
    return ['mp4','mov','avi']
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles") || '.' + this.imageFormats.join(',.') + ',.' + this.videoFormats.join(',.');
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || false;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    const hiddenInputElem = this.file.controller.hiddenInput;
    const tokenElem = document.querySelector(
      'input[name="authenticity_token"]'
    );
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const photo_album_id = document.querySelector("#photo_photo_album_id");
    const payload = {
      authenticity_token: tokenElem.value,
      photo: {
        image: this.hiddenInput.value,
        photo_album_id: photo_album_id.value,
      },
    };
    const fileTemplate = this.file.previewTemplate;
    fetch("/photos", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "X-CSRF-Token": csrf,
        "Content-Type": "application/json",
      },
    }).then(() => {
      const parentElem = document.querySelector('div.dropzone-previews')
      parentElem.removeChild(fileTemplate);
    });
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    previewsContainer: 'div.dropzone-previews',
    previewTemplate: '<div class="p-4"><div class="flex items-start"><div class="flex-shrink-0 pt-0.5"><img class="h-10 w-10 rounded-full" data-dz-thumbnail /> </div> <div class="ml-3 w-0 flex-1"> <span data-dz-name></span> <div class="dz-size" data-dz-size></div><div class="dz-upload w-0 h-4 bg-green-300" data-dz-uploadprogress></div><span data-dz-errormessage></span></div></div></div>'
  });
}
