import ApplicationController from './application_controller';
import { debounce, camelCase } from 'lodash';

export default class extends ApplicationController {
  static values = { reflex: String };

  connect() {
    super.connect();

    this.filter = debounce((elem) => this.filterResults(elem),
      500, { 'maxWait': 1000 }
    );
  }

  filterResults(elem) {
    this.stimulate(this.reflexValue, elem);
  }

  filterValue(event) {
    this.filter(event.target);
  }

  afterReflex (element, reflex, error) {
    if (!error) {
      const params = new URLSearchParams(window.location.search.slice(1))
      element.value === '' ? params.delete(element.name) : params.set(element.name, element.value);
      const qs = params
        .toString()
        .replace(/%28/g, '(')
        .replace(/%29/g, ')')
        .replace(/%2C/g, ',')
      const query = qs.length ? '?' : ''
      history.pushState({}, '', `${window.location.pathname}${query}${qs}`)
    }
  }

}
