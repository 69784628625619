import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['selectedValue', 'listItem'];

  keyUp(event) {
    if (event.keyCode == 40 && event.target.nextElementSibling !== null) {
      this.selectElement(event.target, event.target.nextElementSibling);
      event.target.nextElementSibling.focus();
    } else if (event.keyCode == 38 && event.target.previousElementSibling !== null) {
      this.selectElement(event.target, event.target.previousElementSibling);
      event.target.previousElementSibling.focus();
    }
  }

  toggleBorder(elem) {
    elem.classList.toggle('border-indigo-500')
    elem.classList.toggle('border-transparent')
  }

  clickElement(event) {
    const currentElem = this.element.querySelector('[aria-checked="true"]');
    this.selectElement(currentElem, event.currentTarget)
  }

  selectElement(currentElem, nextElem) {
    this.toggleBorder(currentElem.querySelector('#borderItem'))
    this.toggleBorder(nextElem.querySelector('#borderItem'))
    currentElem.setAttribute('aria-checked', 'false');
    nextElem.setAttribute('aria-checked', 'true');
    this.selectedValueTarget.value = nextElem.getAttribute('data-selectedId-value');
  }
}
