import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['profileMenu', 'projectArrow', 'profileMenuBtn', 'profileMenu'];

  showMobileMenu() {
    // Hide Mobile Menu is handled in menu controller
    const menuElem = document.querySelector('[data-menu-target="mobileMenu"]');
    menuElem.classList.remove('hidden');
  }

  showProfileMenu() {
    this.profileMenuTarget.classList.toggle('hidden');
  }

  hideProfileMenu(event) {
    if (event && (this.profileMenuBtnTarget.contains(event.target) || this.profileMenuTarget.contains(event.target))) {
      return;
    }
    if (!this.profileMenuTarget.classList.contains('hidden')) {
      this.profileMenuTarget.classList.add('hidden');
    }
  }
}