import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  emit(evtType, evtData = null) {
    const evt = new CustomEvent(evtType, {
      bubbles: true,
      detail: evtData,
    });

    this.element.dispatchEvent(evt);
  }
}
