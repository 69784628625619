import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for SortReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static values = { reflex: String };

  connect() {
    super.connect();
  }

  sort(event) {
    event.preventDefault();
    debugger;
    this.stimulate(this.reflexValue, event.currentTarget);
  }

  afterReflex (element, reflex, error) {
    if (!error) {
      const sortBy = element.dataset.sortBy.split('.')
      const params = new URLSearchParams(window.location.search.slice(1))
      const currSortBy = params.get('sort_by') === null ? null : params.get('sort_by').split('.')
      params.delete('sort_by')
      params.set('sort_by', element.dataset.sortBy);
      const qs = params
        .toString()
        .replace(/%28/g, '(')
        .replace(/%29/g, ')')
        .replace(/%2C/g, ',')
      const query = qs.length ? '?' : ''
      history.pushState({}, '', `${window.location.pathname}${query}${qs}`)
      if (currSortBy !== null && currSortBy[0] !== sortBy[0]) {
        this._resetSortIcon(document.getElementById('sort-by-' + currSortBy[0]))
      }
      this._showCorrectSortIcon(element, sortBy[1]);
      element.dataset.sortBy = sortBy[0] + (sortBy[1] === 'desc' ? '.asc' : '.desc');
    }
  }

  _showCorrectSortIcon(element, sortBy) {
    element.querySelector('i').classList.remove('fa-sort', 'fa-sort-' + (sortBy === 'desc' ? 'up' : 'down'));
    element.querySelector('i').classList.add('fa-sort-' + (sortBy === 'desc' ? 'down' : 'up'));
  }

  _resetSortIcon(element) {
    element.querySelector('i').classList.remove('fa-sort-down', 'fa-sort-up');
    element.querySelector('i').classList.add('fa-sort');
  }
}
