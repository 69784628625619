import ApplicationController from './application_controller';
import { debounce, camelCase } from 'lodash';

export default class extends ApplicationController {
  static targets = ['findValue', 'btnClear', 'icoClear', 'icoSearch'];
  static values = { reflex: String };

  connect() {
    super.connect();
    this.setClearBtn();

    this.filter = debounce((elem) => this.filterResults(elem),
      500, { 'maxWait': 1000 }
    );
  }

  clearFilter() {
    if (!this.findValueTarget.value || this.findValueTarget.value == '') {
      return true;
    }

    this.findValueTarget.value = '';
    this.filter(this.findValueTarget);
  }

  setClearBtn() {
    const displayGray = this.findValueTarget.value == '';

    this.icoClearTarget.classList.toggle('text-red-600', !displayGray);
    this.icoClearTarget.classList.toggle('text-gray-400', displayGray);
  }

  toggleSearchIco(searching) {
    this.icoSearchTarget.classList.toggle('fa-search', !searching);
    this.icoSearchTarget.classList.toggle('fa-circle-notch', searching);
    this.icoSearchTarget.classList.toggle('fa-spin', searching);
  }

  filterResults(elem) {
    this.setClearBtn();
    this.toggleSearchIco(true);
    this.stimulate(this.reflexValue, elem);
  }

  searchValue(event) {
    this.filter(event.target);
  }

  afterReflex (element, reflex, error) {
    if (!error) {
      const params = new URLSearchParams(window.location.search.slice(1))
      element.value === '' ? params.delete(element.name) : params.set(element.name, element.value);
      const qs = params
        .toString()
        .replace(/%28/g, '(')
        .replace(/%29/g, ')')
        .replace(/%2C/g, ',')
      const query = qs.length ? '?' : ''
      history.pushState({}, '', `${window.location.pathname}${query}${qs}`)
    }
    this.setClearBtn();
    this.toggleSearchIco(false);
  }

}
