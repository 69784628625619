import { Controller } from 'stimulus';
import {toggle} from 'el-transition';

export default class extends Controller {
  static targets = ['slideFrame', 'fullInsetElem', 'overlay'];

  toggleMenu(event) {
    this.overlayTarget.classList.toggle('hidden')
    toggle(this.slideFrameTarget).then(() => this.fullInsetElemTarget.classList.toggle('hidden'));
    event.stopImmediatePropagation();
  }

  hideMenu(event) {
    this.dropMenuTarget.classList.add('hidden');
  }
}