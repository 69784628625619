import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  addPersonSuccess(element, reflex, noop, reflexId) {
    element.dataset.peopleCount = parseInt(element.dataset.peopleCount) + 1;
  }
}
