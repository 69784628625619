import ApplicationController from './application_controller'
import { debounce } from 'lodash';

/* This is the custom StimulusReflex controller for TrelloEventReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ['txtButler', 'btnSave', 'boardId'];
  connect() {
    super.connect();

    this.parse = debounce((elem) => this.parseText(elem),
      500, { 'maxWait': 1000 }
    );
  }

  toggleSearchIco(searching) {
    this.icoSeachTarget.classList.toggle('fa-search', !searching);
    this.icoSeachTarget.classList.toggle('fa-circle-notch', searching);
    this.icoSeachTarget.classList.toggle('fa-spin', searching);
  }

  parseText(elem) {
    // this.toggleSearchIco(true);
    this.stimulate('trello_event#butler_parse', elem);
  }
  callReflex(elem) {
    fetch();
  }

  parseInput(event) {
    this.parse(event.target);
  }

  afterReflex (element, reflex, error) {
    // if (!error) {
    //   const params = new URLSearchParams(window.location.search.slice(1))
    //   element.value === '' ? params.delete(element.name) : params.set(element.name, element.value);
    //   const qs = params
    //     .toString()
    //     .replace(/%28/g, '(')
    //     .replace(/%29/g, ')')
    //     .replace(/%2C/g, ',')
    //   const query = qs.length ? '?' : ''
    //   history.pushState({}, '', `${window.location.pathname}${query}${qs}`)
    // }
    // this.setClearBtn();
    // this.toggleSearchIco(false);
    console.log(reflex)
  }
}
