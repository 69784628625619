import { Controller } from 'stimulus';
import {toggle} from 'el-transition';

export default class extends Controller {
  static targets = ['dropMenu'];

  toggleMenu(event) {
    // let nodeList = document.querySelectorAll('[name="selected"]:checked')
    // Array.from(nodeList).map(el => el.getAttribute('data-photo-id'))
    // this.dropMenuTarget.classList.toggle('hidden');
    toggle(this.dropMenuTarget);
    event.stopImmediatePropagation();
  }

  hideMenu(event) {
    this.dropMenuTarget.classList.add('hidden');
  }
}