import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dropMenu', 'menuItem'];

  toggleMenu(event) {
    this.dropMenuTarget.classList.toggle('hidden');
    event.stopImmediatePropagation();
  }

  hideMenu(event) {
    this.dropMenuTarget.classList.add('hidden');
  }

  highlight(event) {
    event.target.classList.remove('text-gray-900');
    event.target.classList.add('text-white', 'bg-indigo-600');
  }

  unhighlight(event) {
    event.target.classList.add('text-gray-900');
    event.target.classList.remove('text-white', 'bg-indigo-600');
  }
}